<template>
    <div class="personal" v-if="isShow">
        <div class="personal-wrap">
            <div class="personal-wrap-top">
                <div class="head-img">
                    <img :src="userInfo.headimgurl" alt />
                    <!-- <van-uploader :after-read="afterRead" class="position"> -->
                    <p class="position" @click="chooseImg">修改头像</p>
                    <!-- </van-uploader> -->
                </div>
            </div>
            <ul>
                <li>
                    <p>用户昵称</p>
                    <input type="text" v-model="userInfo.nickname" />
                </li>
                <li>
                    <p>真实姓名</p>
                    <input type="text" placeholder="请输入您的真实姓名" v-model="userInfo.user_name" />
                </li>
                <li>
                    <p>手机号码</p>
                    <input type="number" placeholder="请输入您的真实手机号" v-model="userInfo.user_phone" v-if="showCode" />
                    <input type="number" placeholder="请输入您的真实手机号" :value="userInfo.user_phone" disabled v-else />
                </li>
                <li v-if="showCode">
                    <p>短信验证</p>
                    <input type="number" placeholder="请输入短信验证码" v-model="captcha" />
                    <p :class="['get-code',{'disable':!flag}]" @click="getCode">{{ buttonText }}</p>
                </li>
                <li>
                    <p>微信号</p>
                    <input type="text" placeholder="请输入您的真实微信号" v-model="userInfo.wx_account" />
                </li>
            </ul>
        </div>
        <div class="personal-wrap-btn" @click="postData">
            <button>保存</button>
        </div>
    </div>
</template>
<script>
    import {
        uploadImage,
        getLocalImgData
    } from "@/common/wxJssdk";
    import {
        getUser,
        updataUser,
        sendsmsUpdateUser,
        uploadFileSingle,
        updataHeadimgurl
    } from "@/http/api";
    export default {
        data() {
            return {
                isShow: false,
                userToken: "",
                userInfo: {},
                captcha: '',
                showCode: false,
                flag: true,
                buttonText: "获取验证码"
            };
        },
        mounted() {
            this.userToken = this.$LStorage.getItem("userToken").user_token;
            // 获取个人信息
            this.getUserInfo();
        },
        methods: {
            // 获取用户信息
            async getUserInfo() {
                const res = await getUser({
                    data: {
                        user_token: this.userToken
                    }
                });
                if (res.code == 200) {
                    this.userInfo = res.data;
                    if (this.userInfo.user_phone == "") {
                        this.showCode = true;
                    } else {
                        this.showCode = false;
                    }
                    this.isShow = true;
                }
            },

            // 验证输入信息
            postData() {
                let info = this.userInfo;
                if (this.showCode) {
                    if (info.nickname == "") {
                        this.$toast("请输入用户昵称");
                        return;
                    } else if (info.user_name == "") {
                        this.$toast("请输入您的姓名");
                        return;
                    } else if (info.user_phone == "") {
                        this.$toast("请输入手机号");
                        return;
                    } else if (!this.checkMobile(info.user_phone)) {
                        this.$toast("手机号格式有误");
                        return;
                    } else if (this.captcha == '') {
                        this.$toast("请输入验证码");
                        return;
                    } else if (this.captcha.length != 4) {
                        this.$toast("请输入四位数验证码");
                        return;
                    } else if (info.wx_account == "") {
                        this.$toast("请输入微信号");
                        return;
                    } else {
                        this.updataUserInfo();
                    }
                } else {
                    if (info.user_name == "") {
                        this.$toast("请输入您的姓名");
                        return;
                    } else if (info.wx_account == "") {
                        this.$toast("请输入微信号");
                        return;
                    } else {
                        this.updataUserInfo();
                    }
                }
            },

            // 修改用户信息
            async updataUserInfo() {
                const res = await updataUser({
                    data: {
                        user_token: this.userToken,
                        nickname: this.userInfo.nickname,
                        user_name: this.userInfo.user_name,
                        user_phone: this.userInfo.user_phone,
                        wx_account: this.userInfo.wx_account,
                        captcha: this.captcha
                    }
                });
                if (res.code == "200") {
                    let that = this;
                    that.$toast.success({
                        "message": "修改成功",
                        "duration": 2,
                        "onClose": function(){ 
                            that.$router.go(-1);
                        }
                    });
                } else {
                    this.$toast.fail(res.msgs);
                }
            },

            // 获取验证码验证
            getCode() {
                let user = this.userInfo;
                if (user.user_phone == "") {
                    this.$toast("请输入手机号");
                    return false;
                } else if (!this.checkMobile(user.user_phone)) {
                    this.$toast("请输入正确的手机号");
                    return false;
                } else {
                    if (this.flag) {
                        let time = 60;
                        this.buttonText = "已发送";
                        this.sendsmsUpdateUser();
                        this.flag = false;
                        let timer = setInterval(() => {
                            time--;
                            this.buttonText = time + " 秒";
                            if (time === 0) {
                                clearInterval(timer);
                                this.buttonText = "重新获取";
                                this.isDisabled = false;
                                this.flag = true;
                            }
                        }, 1000);
                    }
                }
            },

            // 验证手机号
            checkMobile(str) {
                let re = /^1[3456789]\d{9}$/;
                if (re.test(str)) {
                    return true;
                } else {
                    return false;
                }
            },

            // 获取验证码
            async sendsmsUpdateUser() {
                const res = await sendsmsUpdateUser({
                    data: {
                        user_phone: this.userInfo.user_phone
                    }
                });
                if (res.code == "200") {
                    this.$toast('修改成功')
                } else {
                    this.$toast.fail(res.msgs);
                }
            },

            //vant 上传图片  监听
            async afterRead(files) {
                console.log(files.file);
                var formData = new FormData();
                formData.append("imgs", files.file);
                const res = await uploadFileSingle(formData);
                if (res.code == "200") {
                    this.updataHeadimgurl(res.data.material_url);
                } else {
                    this.$toast.fail(res.msgs);
                }
            },

            //微信jssdk上传图片
            chooseImg() {
                uploadImage({}, res => {
                    console.log(res);
                    if (res.status) {
                        this.updataHeadimgurl(res.imgs[0]);
                    } else {
                        this.$toast.fail(res.msg);
                    }
                });
            },

            async updataHeadimgurl(data) {
                console.log(data)
                const res = await updataHeadimgurl({
                    data: {
                        user_token: this.userToken,
                        headimgurl: data
                    }
                });
                if (res.code == 200) {
                    this.$toast.success("上传成功");
                    this.userInfo.headimgurl = res.data.headimgurl;
                    this.$LStorage.setItem("userInfo", this.userInfo);
                }
            }
        }
    };
</script>
<style lang="scss">
    .personal {
        width: 100%;
        height: 13.8rem;
        background: #f6f6f6;

        .home-top {
            width: 100%;
            display: flex;
            justify-content: space-between;
            padding: 0.3rem;
            box-sizing: border-box;
            font-size: 0.38rem;
            background: #f4f4f4;
        }

        .personal-wrap {
            width: 100%;
            background: #fff;
            // height: 7.3rem;
            padding: 0 0.23rem 0 0.23rem;
            box-sizing: border-box;

            .personal-wrap-top {
                width: 1.64rem;
                margin: 0 auto;
                padding-top: 0.63rem;
                padding-bottom: 0.96rem;
                box-sizing: border-box;

                .head-img {
                    position: relative;

                    img {
                        width: 1.57rem;
                        height: 1.51rem;
                        border-radius: 50%;
                        border: 2px solid #fb6f15;
                        object-fit: cover;
                    }

                    .position {
                        width: 1.38rem;
                        height: 0.4rem;
                        background: #fc6f15;
                        font-size: 0.24rem;
                        text-align: center;
                        line-height: 0.4rem;
                        color: #fff;
                        border-radius: 0.2rem;
                        position: absolute;
                        top: 1.21rem;
                        left: 50%;
                        transform: translateX(-50%);

                        p {
                            font-size: 0.24rem;
                            margin-top: 0;
                            text-align: center;
                            width: 1.34rem;
                        }
                    }
                }
            }

            ul {
                width: 100%;

                li {
                    font-size: 0.27rem;
                    border-bottom: 1px solid #f0f0f0;
                    display: flex;
                    height: 1.08rem;

                    p {
                        color: #3f3f50;
                        line-height: 1.08rem;
                        width: 1.6rem;
                    }

                    input {
                        color: #333;
                        width: calc(100% - 3.2rem);
                    }

                    .get-code {
                        margin-right: 0;
                        color: #e50012;
                        text-decoration: underline;
                        margin-right: 0;
                        text-align: right;
                    }

                    .disable {
                        color: #999;
                    }
                }

                li:last-child {
                    border: none;
                }
            }
        }

        .personal-wrap-btn {
            height: 0.84rem;
            text-align: center;
            line-height: 0.84rem;
            background: #e50012;
            color: #fff;
            border-radius: 0.42rem;
            font-size: 0.3rem;
            margin: 1.17rem 0.34rem;

        }
    }
</style>
